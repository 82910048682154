/* Icons from Font-Awesome  | Font Awesome by Dave Gandy - http://fontawesome.io*/
/* Thanks to http://fa2png.io/ for icon png generation */

.leaflet-control-navbar-fwd {
background-image: url("img/arrow-right_000000_14.png");
}

.leaflet-control-navbar-back {
background-image: url("img/arrow-left_000000_14.png");
}

.leaflet-control-navbar-home {
background-image: url("img/home_000000_14.png");
}


.leaflet-control-navbar-fwd-disabled {
background-image: url("img/arrow-right_bbbbbb_14.png");
}

.leaflet-control-navbar-back-disabled {
background-image: url("img/arrow-left_bbbbbb_14.png");
}

.leaflet-control-navbar-home-disabled {
background-image: url("img/home_bbbbbb_14.png");
}
